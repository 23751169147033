@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;  
  src:  url('FiraSans-Regular.woff2') format('woff2'),
        url('FiraSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;  
  src:  url('FiraSans-Medium.woff2') format('woff2'),
        url('FiraSans-Medium.woff') format('woff');
}
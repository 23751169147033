@media (min-width: $xl) {
  .cta-wrap1 {
    // max-width: 1680px;
    margin-left: 110px;
    margin-right: 110px;
  }
}

.cta-title1 {
  margin-bottom: -1rem;
  color: $white-color;
}

.video-modal {
  iframe {
    height: 490px;
    width: 100%;
  }

  .modal-closer {
    border: none;
    padding: 0;
    color: var(--white-color);
    font-size: 35px;
    background-color: transparent;
    position: absolute;
    top: -7px;
    right: -39px;
  }

  .modal-content {
    border-radius: 0;
    background-color: transparent;
  }

  .modal-body {
    padding: 0;
    line-height: 0;
    border-radius: 0;
  }
}

@include lg {
  .cta-title1 {
    margin-bottom: -0.7rem;
  }
}

@include md {
  .cta-title1 {
    margin: -.5em 0 30px 0;
  }


  .video-modal {
    .modal-closer {
      font-size: 24px;
      top: -30px;
      right: 0;
    }

    iframe {
      height: 320px;
      width: 100%;
    }
  }
}
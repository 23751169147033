.process-style1 {
  text-align: center;
  margin: 0 0 5px 0;
  position: relative;

  .process-arrow {
    position: absolute;
    right: -53px;
    top: 0;
  }

  &:nth-child(even) {
    margin-top: 60px;

    .process-arrow {
      transform: rotateX(190deg);
    }
  }

  &:last-child {
    .process-arrow {
      display: none;
    }
  }
  
  .process-icon {
    position: relative;
    text-align: center;
    width: var(--icon-size, 130px);
    height: var(--icon-size, 130px);
    line-height: var(--icon-size, 130px);
    box-shadow: 0px 0px 77px 10px rgba(170, 170, 170, 0.13);
    background-color: $white-color;
    border-radius: 50%;
    margin: 0 auto 30px auto;
  }

  .process-number {
    text-align: center;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 26px;
    background-color: $theme-color;
    color: $white-color;
    border-radius: 50%;
    position: relative;
    border: 5px solid $white-color;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: 700;
    font-family: $title-font;
  }

   .process-title {
     margin: 0 0 10px 0;
   }
}

.process-style2 {
  text-align: center;
  max-width: 290px;
  position: relative;
  margin: 0 0 5px 0;

  .process-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    position: relative;
    display: inline-block;
    background-color: $white-color;
    margin: 22.5px 0 25px 0;
    box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
    border-radius: 5px;
  }

  .process-number {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-weight: 700;
    font-family: $title-font;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $theme-color;
    color: $white-color;
    margin: -22.5px -22.5px 0 0;
    border-radius: 50%;
  }

  .process-arrow {
    position: absolute;
    right: -55%;
    top: 10%;
  }

  &:last-child {
    .process-arrow {
      display: none;
    }
  }

}

@media (min-width: $xl) {
  .process-wrap1 {
    margin-left: 120px;
    margin-right: 120px;
  }
}

@include lg {
  .process-style1 {
    
    &,
    &:nth-child(even) {
      margin-top: 0;
    }
    
    .process-arrow {
      right: -45px;
      top: 25px;
      max-width: 80px;
    }
  }

  .process-style2 {
    .process-arrow {
      right: -23%;
      top: 15%;
      max-width: 90px;
    }
  }
}

@include md {
  .process-style1 {    
    .process-arrow {
      display: none;
    }

    .process-icon {
      --icon-size: 100px;
      margin: 0 auto 20px auto;

      img {
        max-width: 40px;
      }
    }
  }

  .process-style2 {
    max-width: 100%;
    
    .process-arrow {
      display: none;
    }
  }
}
.project-style1 {
  background-color: $white-color;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  margin: 0 0 30px 0;

  .project-img {
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: scale(1.1);
      background-color: $theme-color;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.4s;
      z-index: 1;
    }

    img {
      transition: all ease 0.4s;
      transform: scale(1);
      width: 100%;
    }
  }

  .project-content {
    padding: 25px 30px 30px 30px;
  }

  
  &:hover {
    .vs-btn.style3 {
      background-color: $theme-color;
      color: $white-color;
    }

    .project-img {
      &:before {
        transform: scale(1);
        opacity: 0.5;
        visibility: visible;
      }

      img {
        transform: scale(1.1);
      }
    }
  }
}

.slick-slide .project-style1 {
  box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
}

.project-style2 {
  position: relative;
  margin: 0 0 30px 0;

  .project-img {
    overflow: hidden;
    position: relative;

    img {
      transition: all ease 0.4s;
      transform: scale(1);
      width: 100%;
    }
  }

  .project-shape {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    background-color: $theme-color;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.4s;
  }

  .icon-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    background-color: $white-color;
    color: $theme-color;
    opacity: 0;
    visibility: hidden;

    &:hover {
      background-color: $title-color;
      color: $white-color;
    }
  }

  .project-title {
    color: $white-color;
    margin: 0;
  }

  .project-label {
    font-size: 16px;
    font-weight: 600;
    font-family: $title-font;
    color: $white-color;
    display: block;
    margin: 0 0 5px 0;
  }

  .project-label,
  .project-title {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50px);
    transition: all ease 0.4s;
  }

  .project-content {
    position: absolute;
    left: 70px;
    bottom: 60px;
    z-index: 3;
  }

  &:hover {
    .project-shape {
      opacity: 0.80;
      visibility: visible;
    }

    .project-img {
      img {
        transform: scale(1.1);
      }
    }

    .icon-btn {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.2s;
    }

    .project-title,
    .project-label {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition-delay: 0.1s;
    }

    .icon-btn,
    .project-label {
      transition-delay: 0.2s;
    }
  }

}

.project-box {
  background-color: $smoke-color;
  padding: 45px 40px;
  margin: 0 0 30px 0;

  &__title {
    margin: -0.3em 0 25px 0;
  }

  .media-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: $white-color;
    text-align: center;
    color: $theme-color;
    margin: 0 20px 0 0;
    font-size: 18px;
    border-radius: 50%;
  }

  .media-label {
    font-size: 14px;
    display: block;
    margin: 0 0 10px 0;
    line-height: 1;
  }

  .media-link {
    font-size: 16px;
    font-weight: 600;
    color: $theme-color;
    margin: 0;
    font-family: $title-font;
  }

  .info-media {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #DADFEC;
      margin: 0 0 30px 0;
      padding: 0 0 30px 0;
    }
  }


}


.widget-contact {
  background-color: $theme-color;
  padding: 32px 40px 20px 40px;
  
  &__title {
    color: $white-color;
    margin: 0 0 10px 0;
  }

  &__text {
    color: $white-color;
    margin: 0 0 22px 0;
  }

  &__info {
    color: $white-color;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 0 33px;

    a {
      color: inherit;
    }

    i {
      margin: 0 15px 0 0;
      position: absolute;
      left: 0;
      top: 3.5px;
    }
  }
}

@include xl {
  .project-style2 {
    .icon-btn {
      top: 60px;
      right: 60px;
      left: auto;
      transform: none;
      --btn-size: 40px;
      --btn-font-size: 18px;
    }
  }
}


@include ml {
  .project-box {
    padding: 35px 25px;
  }

  .widget-contact {
    padding: 32px 25px 20px 25px;
  }

  .project-style1 {
    .project-img {
      img {
        width: 100%;
      }
    }

    .project-content {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}


@include md {
  .project-style2 {
    .project-shape {
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
    }
    
    .project-content {
      left: 20px;
      bottom: 20px;
    }

    .icon-btn {
      top: 20px;
      right: 20px;
    }
  }
}

@include xs {
  .project-style1 {
    .project-content {
      padding: 25px 20px 30px 20px;
    }
  }
}
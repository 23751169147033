
.hero-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @extend .animated;
}

.hero-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 1;
}


.hero-layout1 {

  .slick-arrow {
    --pos-x: 150px;
    --icon-font-size: 24px;
    --icon-size: 67px;
    background-color: transparent;
    line-height: 65px;
    color: $white-color;
    border: 2px solid $white-color;
    top: calc(50% - 37.5px);
  }

  .hero-inner {
    position: relative;
    min-height: 850px;
    display: flex;
    align-items: center;
    background-color: $title-color;
    padding-bottom: 75px;
    overflow: hidden;
  }

  .hero-content {
    position: relative;
    z-index: 4;
    padding: 80px 0;
  }

  .hero-title {
    color: $white-color;
    text-transform: uppercase;
    margin: -0.2em 0 20px 0;
    font-size: 60px;
    line-height: 1.3;
    animation-delay: 0s;
    @extend .animated;
  }

  .hero-text {
    color: $white-color;
    max-width: 690px;
    line-height: 28px;
    margin: 0 0 30px 0;
    animation-delay: 0.2s;
    @extend .animated;
  }

  .hero-btns {
    animation-delay: 0.4s;
  }

  .hero-innertext {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    border: 2px solid $white-color;
    font-family: $title-font;
    color: $white-color;
    border-radius: 5px;
    padding: 9px 23.5px;
    vertical-align: middle;
    position: relative;
    top: -4px;
    margin-left: 15px;
    display: inline-block;
  }

  .hero-shape1 {
    position: absolute;
    top: -473px;
    left: 51px;
    z-index: 2;
    width: 300px;
    height: 1558px;
    background-color: rgba(14, 84, 245, 0.5);
    transform: rotate(43.46deg);
  }

  .hero-shape2 {
    position: absolute;
    transform: rotate(43.46deg);
    top: -485px;
    left: 406px;
    z-index: 2;
    width: 589px;
    height: 1819.7px;
    background: linear-gradient(172deg, rgba(5, 26, 79, 0.35) 21%, rgba(0, 0, 0, 0) 54%);
    background-position: 0% 0%;
    background-repeat: no-repeat;
  }

  .slick-current {
    --animation-name: slideinup;
  }
}


.hero-layout2 {
  position: relative;

  .hero-inner {
    min-height: 980px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-bg-text {
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 300px;
    z-index: 1;
    font-family: $title-font;
    color: rgba(255, 255, 255, 0.05);
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.10);
    display: inline-block;
    @extend .animated;
    animation-delay: 1s;
  }

  .hero-subtitle {
    color: $white-color;
    font-family: $title-font;
    font-size: 20px;
    font-weight: 600;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
    @extend .animated;
    position: relative;
    z-index: 5;
  }

  .hero-title {
    color: $white-color;
    text-align: center;
    font-weight: 700;
    font-size: 72px;
    line-height: 1.2;
    max-width: 810px;
    margin: 0 auto 40px auto;
    @extend .animated;
    animation-delay: 0.5s;
    position: relative;
    z-index: 5;
  }

  .hero-btns {
    justify-content: center;
    animation-delay: 0.8s;
    position: relative;
    z-index: 5;
  }

  .hero-shape2,
  .hero-shape1 {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    z-index: 3;
    line-height: 1;
  }

  .hero-shape1 {
    img {
      width: 100%;
    }
  }

  .hero-shape2 {
    z-index: 2;
    @extend .animated;
    animation-delay: 1.5s;
  }

  .hero-play {
    position: absolute;
    left: 16.5%;
    bottom: 18.6%;
    z-index: 4;
    display: flex;
    align-items: center;
    @extend .animated;
    animation-delay: 1s;

    .play-btn {
      margin-right: 30px;
    }

    &__title {
      color: $white-color;
      text-transform: capitalize;
      font-size: 24px;
      font-weight: 700;
      font-family: $title-font;
      display: block;
      margin: 3px 0 3px 0;
    }

    &__label {
      color: $white-color;
      font-size: 16px;
      font-family: $title-font;
      margin: 0;
    }
  }

  .slick-current {
    --animation-name: slideinup;

    .hero-bg-text {
      --animation-name: fadein;
    }

    .hero-shape2 {
      --animation-name: slideinleft;
    }

    .hero-play {
      --animation-name: slideinright;
    }
  }
}


.hero-layout3 {

  .hero-img {
    img {
      border-radius: 22px;
    }
  }

  .hero-inner {
    min-height: 850px;
    padding: 100px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .hero-subtitle {
    color: $white-color;
    font-size: 18px;
    font-weight: 600;
    font-family: $title-font;
    display: block;
    margin: -0.2em 0 15px 0;
    @extend .animated;
  }

  .hero-title {
    color: $white-color;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.27;
    margin: 0 0 25px 0;
    @extend .animated;
    animation-delay: 0.5s;
  }

  .inner-text {
    font-weight: 300;
  }

  .hero-btns {
    animation-delay: 1s;
  }

  .hero-text {
    color: $white-color;
    font-family: $title-font;
    line-height: 28px;
    margin: 0 0 30px 0;
    @extend .animated;
    animation-delay: 0.8s;
  }

  .slick-dots {
    position: absolute;
    top: 50%;
    left: 225px;
    margin: 0;
    transform: translateY(-50%);

    button {
      font-size: 30px;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-color: transparent;
      color: $white-color;
      border: 2px solid $white-color;
      font-family: $title-font;
      font-size: 20px;
      font-weight: 700;

      &::before {
        display: none;
      }
    }

    li {
      display: block;
      margin: 0 0 20px 0;

      &.slick-active {
        button {
          transform: scale(1.1);
          background-color: $white-color;
          color: $theme-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .slick-current {
    --animation-name: slideinup;
  }
}


@include xl {
  .hero-layout1 .slick-arrow {
    --pos-x: 20px;
    --icon-font-size: 19px;
    --icon-size: 45px;
    line-height: 42px;
  }
  
  
  .hero-layout2 {
    .hero-shape2 {
      max-width: 450px;
    }

    .hero-play {
      left: 14.5%;
      bottom: 13.6%;
    }

    .hero-bg-text {
      font-size: 220px;
    }

    .hero-title {
      font-size: 60px;
      max-width: 700px;
    }

    .hero-subtitle {
      font-size: 18px;
    }

    .hero-inner {
      min-height: 800px;
    }

    .hero-play .play-btn {
      --icon-size: 100px;
      --icon-font-size: 24px;
    }
  }


  .hero-layout3 {

    .slick-dots {
      top: auto;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);

      button {
        width: 40px;
        height: 40px;
        line-height: 38px;
        font-size: 16px;
      }

      li {
        display: inline-block;
        margin: 0 10px 0 0;

        &:last-child {
          margin-right: 0;
        }

        &.slick-active {
          button {
            transform: none;
          }
        }
      }
    }
  }
}

@include ml {
  .hero-layout2 {

    .hero-inner {
      min-height: 700px;
    }

    .hero-shape2 {
      max-width: 350px;
    }

    .hero-play {
      left: 10.5%;
      bottom: 10.6%;

      .play-btn {
        margin-right: 20px;
      }

      &__title {
        font-size: 18px;
      }

      &__label {
        font-size: 14px;
      }
    }

    .hero-title {
      font-size: 48px;
      max-width: 635px;
    }

    .hero-subtitle {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .hero-bg-text {
      font-size: 190px;
    }
  }

  .hero-layout3 {
    .hero-title {
      font-size: 48px;
    }

    .hero-inner {
      min-height: 750px;
    }
  }
}

@include lg {  
  .hero-layout1 {
    .hero-inner {
      min-height: 600px;
      padding-bottom: 0;
    }

    .hero-title {
      font-size: 46px;
    }

    .hero-innertext {
      font-size: 14px;
      padding: 7px 18px;
    }

    .hero-shape1 {
      top: -645px;
      left: 10px;
      width: 200px;
      height: 1490px;
    }

    .hero-shape2 {
      display: none;
    }
  }

  .hero-layout3 {
    .hero-inner {
      min-height: initial;
    }

    .hero-title {
      font-size: 42px;
    }
  }
}


@include md {
  .hero-layout1 {
    text-align: center;
    
    .hero-inner {
      min-height: auto;
    }

    .hero-title {
      font-size: 36px;
      margin-bottom: 15px;
    }

    .hero-innertext {
      font-size: 12px;
      padding: 5px 15px;
      top: 0;
    }

    .hero-btns {
      justify-content: center;
    }
  }

  .hero-layout2 {
    .hero-inner {
      padding: 70px 0 100px 0;
      min-height: initial;
    }

    .hero-shape2 {
      max-width: 200px;
    }

    .hero-bg-text {
      font-size: 130px;
    }

    .hero-play {
      position: relative;
      bottom: 0;
      left: 0;
      width: max-content;
      margin: 30px auto 0 auto;
      display: flex;
      flex-direction: column-reverse;
      text-align: center;

      .play-btn {
        --icon-font-size: 18px;
        --icon-size: 60px;
        margin: 0 0 20px 0;
      }

      &__label,
      &__title {
        display: none;
      }
    }

    .hero-title {
      font-size: 40px;
      max-width: 530px;
    }
  }

  .hero-layout3 {
    .hero-img {
      margin-bottom: 40px;

      img {
        width: 100%;
      }
    }

    .hero-content {
      text-align: center;
    }

    .hero-btns {
      justify-content: center;
    }
  }
}

@include sm {  
  .hero-layout1 {
    .hero-title {
      font-size: 30px;
    }

    .hero-innertext {
      border: none;
      padding: 0;
      display: inline;
      font-size: inherit;
      margin: 0;
      vertical-align: top;
      text-decoration: underline;
    }
  }

  .hero-layout2 {
    .hero-title {
      font-size: 34px;
      margin-bottom: 30px;
    }

    .hero-subtitle {
      font-size: 14px;
    }

    .hero-bg-text {
      font-size: 70px;
    }

    .hero-shape2 {
      max-width: 150px;
    }
  }


  .hero-layout3 {
    .hero-subtitle {
      font-size: 16px;
    }

    .hero-title {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
}
.vs-header {
  position: relative;
  z-index: 41;
}

.will-sticky {
  .sticky-active {
    position: fixed;
    top: -100%;
    right: 0;
    left: 0;
    background-color: $white-color;
    transition: all ease 0.8s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);

    &.active {
      top: 0;
    }
  }
}

.main-menu {
  a {
    display: block;
    position: relative;
    font-family: $title-font;
    font-weight: 600;
    font-size: 16px;
    color: $title-color;

    &:hover {
      color: $theme-color;
    }
  }

  > ul {
    > li {
      margin: 0 18px;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      display: inline-block;
      position: relative;

      &.menu-item-has-children {
        > a {
          &:after {
            content: "\f078";
            position: relative;
            font-family: $icon-font;
            margin-left: 5px;
            font-size: 0.8rem;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        > ul.sub-menu,
        ul.mega-menu {
          visibility: visible;
          opacity: 1;
          margin-top: 0;
          z-index: 9;
        }
      }
    }
  }

  ul.sub-menu,
  ul.mega-menu {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background-color: $white-color;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    visibility: hidden;
    min-width: 190px;
    width: max-content;
    padding: 7px;
    left: -14px;
    margin-top: 50px;
    opacity: 0;
    z-index: -1;
    border-bottom: 3px solid $theme-color;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09),
      0px 3px 0px 0px rgba(231, 13, 60, 0.004);
    transform-origin: top center;
    transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s,
      opacity 0.4s ease-in-out 0s, z-index 0s;

    a {
      font-size: 16px;
      line-height: 30px;
    }
  }

  ul.sub-menu {
    padding: 18px 20px;
    left: -27px;

    &:before {
      content: "";
      position: absolute;
      left: 34.5px;
      top: 30px;
      width: 1px;
      background-color: #ededed;
      height: calc(100% - 60px);
    }

    li {
      display: block;
      margin: 0 0;
      padding: 3px 9px;

      &.menu-item-has-children {
        > a:after {
          content: "\f105";
          float: right;
          top: 3px;
        }
      }

      a {
        position: relative;
        padding-left: 21px;

        &:before {
          content: "\f111";
          position: absolute;
          top: 2.5em;
          left: 0;
          font-family: $icon-font;
          width: 11px;
          height: 11px;
          text-align: center;
          border-radius: 50%;
          display: inline-block;
          font-size: 0.2em;
          line-height: 11.5px;
          color: $theme-color;
          font-weight: 700;
          background-color: $white-color;
          box-shadow: inset 0px 2px 4px 0px rgba(#ad8858, 0.4);
        }
      }

      ul.sub-menu {
        left: 100%;
        right: auto;
        top: 0;
        margin: 0 0;
        margin-left: 20px;

        li {
          ul {
            left: 100%;
            right: auto;
          }
        }
      }
    }
  }

  .mega-menu-wrap {
    position: static;
  }

  ul.mega-menu {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    max-width: var(--main-container);
    padding: 20px 15px 23px 15px;
    left: 50%;
    transform: translateX(-50%);

    .main-menu ul.mega-menu > li > ul > li > a {
      position: relative;
    }

    li {
      display: block;
      width: 100%;
      padding: 0 15px;

      li {
        padding: 4px 0;
      }

      a {
        display: inline-block;
      }
    }

    > li {
      > a {
        display: block;
        padding: 0;
        padding-bottom: 15px;
        margin-bottom: 10px;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-weight: 700;
        color: $title-color;
        border-color: $theme-color;

        &::after,
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 15px;
          height: 1px;
          background-color: $theme-color;
        }

        &::after {
          width: calc(100% - 20px);
          left: 20px;
        }

        &:hover {
          padding-left: 0;
        }
      }

      > ul {
        > li {
          > a {
            &:before {
              content: "\f105";
              font-family: $icon-font;
              font-weight: 400;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}

.menu-style1 {
  > ul {
    > li {
      > a {
        padding: 16.5px 0;
      }
    }
  }
}

.menu-style2 {
  > ul {
    > li {
      > a {
        padding: 41.5px 0;
      }
    }
  }
}

.menu-style3 {
  > ul {
    > li {
      > a {
        padding: 45px 0;
      }
    }
  }
}

.note_style1 {
  margin: 0;

  .note_title {
    font-weight: 500;
    margin-right: 0.5rem;
  }

  i {
    margin-right: 10px;
  }
}

.header-media {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  margin-left: 40px;
  padding-left: 40px;
  border-left: 1px solid #e7ebf3;

  &:nth-child(2) {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .media-icon {
    color: $theme-color;
    font-size: 24px;
    margin-right: 20px;
  }

  .media-label {
    font-size: 14px;
    color: $body-color;
    display: block;
    margin-bottom: 2px;
  }

  .media-link {
    color: $title-color;
    font-size: 20px;
    font-weight: 600;
    margin: 0;

    a {
      color: inherit;

      &:hover {
        color: $theme-color;
      }
    }
  }
}

.search_form1 {
  display: flex;
  width: 340px;
  max-width: 100%;

  input {
    flex: 1;
    border: none;
    padding: 0 20px 0 20px;
    height: 35px;
    width: 100%;
    border-radius: 4px;
    transition: all ease 0.4s;
  }

  button {
    border: none;
    background-color: $theme-color;
    color: $white-color;
    font-size: 13px;
    width: 35px;
    height: 35px;
    border-radius: 4px;

    &:hover {
      background-color: $title-color;
      color: $white-color;
    }
  }

  input {
    &:focus {
      padding: 0 20px 0 20px;
      background-color: $smoke-color;
    }
  }
}

.header-links {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $title-color;
    font-family: $title-font;
    padding: 0 30px 0 0;
    margin: 0 26px 0 0;
    border-right: 1px solid #cad4f1;
    line-height: 23px;

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }

  i {
    color: $theme-color;
    margin: 0 10px 0 0;
  }

  a {
    color: inherit;

    &:hover {
      color: $theme-color;
    }
  }

  &.style-white {
    i,
    li {
      color: $white-color;
      border-color: #558eff;
    }
  }
}

.header-social {
  font-size: 14px;

  .social-title {
    color: $title-color;
    display: inline-block;
  }

  a {
    display: inline-block;
    color: $title-color;
    margin-left: 16px;

    &:hover {
      color: $theme-color;
    }
  }

  &.style-white {
    .social-title,
    a {
      color: $white-color;
    }

    a {
      &:hover {
        color: $title-color;
      }
    }
  }
}

.logo-style1 {
  display: block;
  height: 100%;
  // background: linear-gradient(278.46deg, #00BCFA 0.91%, #0E59F2 71.89%, #0E59F2 100%);
  background: "#fff";
  padding: 20px 35px 20px 35px;
  border-radius: 5px 0px 0px 5px;
}

.header-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.header-box {
  border: 10px solid $theme-color;
  background-color: $white-color;
  padding: 20px;

  .media-icon {
    border-radius: 5px;
    margin: 0 15px 0 0;
    font-size: 18px;
    width: 43px;
    height: 43px;
    line-height: 43px;
    text-align: center;
    display: inline-block;
    background-color: $theme-color;
    color: $white-color;
  }

  .media-label {
    display: inline-block;
    color: $title-color;
    font-weight: 600;
    font-family: $title-font;
    padding-left: 60px;
  }

  .media-link {
    display: block;
    width: fit-content;
    font-family: $title-font;
    font-size: 22px;
    font-weight: 700;
    color: $theme-color;
    margin: 23px 0 0 0;
  }

  .media-body {
    margin-top: -34px;
  }
}

.header-layout3,
.header-layout1 {
  .header-top {
    background-color: $theme-color;
    padding: 9.5px 0;
  }
}

.header-layout1 {
  .menu-top {
    border-bottom: 1px solid #e7ebf3;
  }

  .note_style1 {
    color: $white-color;
  }
}

.header-layout2 {
  position: relative;
  margin-bottom: -55px;

  .header-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 55px);
    z-index: -1;
    background-color: #f6f7fa;
  }

  .header-top {
    padding: 27px 0;
  }

  .menu-area {
    background-color: $white-color;
    padding: 0 30px 0 0;
    border-radius: 5px;
    box-shadow: 0px 17px 29px rgba(32, 56, 105, 0.18);
  }

  .vs-menu-toggle {
    border-radius: 5px;
  }

  .will-sticky .sticky-active {
    box-shadow: none;
    background-color: transparent;
  }
}

.header-layout3 {
  position: relative;

  @media (min-width: $lg) {
    .header-box-wrap,
    .header-top,
    .sticky-wrapper:not(.will-sticky) {
      --box-width: 290px;
      --main-container: 1680px;

      .container {
        padding-right: var(--box-width);
      }
    }

    .header-box {
      width: calc(var(--box-width) - 40px);
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: $xl) and (min-width: $lg) {
    --main-container: 1320px;

    .main-menu > ul > li {
      margin: 0 12px;
    }
  }
}

@include hd {
  .logo-style1 {
    margin-bottom: -1px;
  }
}

@include ml {
  .header-layout3 {
    .main-menu > ul > li {
      margin: 0 13px;
    }
  }
}

@include lg {
  .search_form1 {
    width: 200px;
  }

  .main-menu > ul > li {
    margin: 0 14px;
  }

  .header-layout2 {
    margin: 0;

    .header-shape {
      height: 100%;
    }
  }
}

@include md {
  .header-links {
    li {
      padding-right: 15px;
      margin-right: 10px;
    }
  }

  .header-logo {
    padding: 15px 0;
    display: inline-block;
  }

  .logo-style1 {
    padding: 20px 20px 20px 20px;
  }

  .header-layout1 {
    .vs-menu-toggle {
      margin: 10px 0;
    }
  }

  .header-layout2 {
    .menu-area {
      padding: 0 20px 0 0;
    }
  }
}

@include xs {
  .header-media {
    &:nth-child(2) {
      padding-left: 10px;
    }

    .media-label {
      font-size: 12px;
    }

    .media-link {
      font-size: 14px;
    }

    .media-icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .header-links {
    li {
      padding-right: 20px;
      margin-right: 0;
      border-right: none;
      font-size: 13px;

      i {
        margin-right: 5px;
      }
    }
  }

  .logo-style1 {
    padding: 15px 15px 15px 15px;
    max-width: 200px;
  }

  .header-layout2 {
    .header-top {
      padding: 13px 0;
    }
  }
}
